#root {
  display: flex;
  flex-direction: column;
  z-index: 0;
  min-height: 100%;
  overflow-x: hidden;
}

.reactModalContainer > * {
  pointer-events: auto;
  overflow: auto;
  height: 100vh;
}

.reactModalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  pointer-events: none;
  z-index: 1800;
  width: 100vw;
}

html {
  height: 100%;
}
